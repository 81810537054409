import { Flex, FormLabel, Input, Text, useColorModeValue, Tooltip, Spacer } from '@chakra-ui/react';
import React from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

type InputFieldProps = {
	id?: string;
	label?: string;
	extra?: JSX.Element;
	placeholder?: string;
	type?: string;
	mb?: string;
	defaultValue?: any;
	[x: string]: any;
};

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
	const {
		id,
		label,
		extra,
		placeholder,
		type = 'text',
		mb,
		defaultValue,
		value,
		...rest
	} = props;

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Flex direction='column' mb={mb ? mb : '30px'}>
			<FormLabel
				display='flex'
				ms='10px'
				htmlFor={id}
				fontSize='sm'
				color={textColorPrimary}
				fontWeight='bold'
				_hover={{ cursor: 'pointer' }}>
				{label}
				{props.toolTipText && (
					<Flex>
						&nbsp;
					<Tooltip label={props.toolTipText} placement="right" ml="2">
						<InfoOutlineIcon />
					</Tooltip>
					</Flex>
					)}
				<Text fontSize='sm' fontWeight='400' ms='2px'>
					{extra}
				</Text>
			</FormLabel>
			<Input
				{...rest}
				ref={ref}
				type={type}
				id={id}
				fontWeight='500'
				variant='main'
				placeholder={placeholder}
				_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
				defaultValue={defaultValue}
				h='44px'
				maxH='44px'
			/>
		</Flex>
	);
});

export default InputField;
